let BASE_URL, SECOND_URL;

// if (process.env.REACT_APP_ENV === 'production') {
//     BASE_URL = 'http://api.template.4-com.pro/api/v0/';
// } else {
if (window.location.host === 'localhost:3000') {
    BASE_URL = 'https://api.gaming-stars.net';
} else if (localStorage.getItem("BASE_URL")) {
    BASE_URL = localStorage.getItem("BASE_URL");
} else {
    BASE_URL = 'https://api.gaming-stars.net';
}
// }

SECOND_URL = '';

export const API_BASE_URL = BASE_URL;
export const API_SECOND_URL = SECOND_URL;
